@use "sass:list";
@use "sass:map";

$blue:    #2C9FA3 !default;
$red:     #B31237 !default;
$orange:  #FF8826 !default;
$yellow:  #FFB914 !default;
$green:   #9dbc23;
$darkgreen: #238b45;
$purple: #8c6bb1;
$orange:  #DC8D6D;

$primary: $blue;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  // Use 1194px instead of 1200 to capture devices like the iPad Pro 2019.
  xl: 1194px,
  xxl: 1600px,
  x3l: 2200px,
  x4l: 3000px,
  x5l: 3500px
) !default;

$editor-outline: lightgray;
$mark-bg: #f0f0c3 !default;
