/* You can add global styles to this file, and also import other style files */
@import "_customizations.scss";
@import "~bootstrap/scss/bootstrap.scss";
// @import "~prosemirror-view/style/prosemirror.css";

@import "~mapbox-gl/dist/mapbox-gl.css";

@import "variables";
@import "mixins";

// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

.orange {
    color: #e7683d;
}
.bold {
    font-weight: bold;
}

html, body {
    height: 100vh;
    min-height: 100vh;
}

#google_esf {
    visibility: none;
}

header {
    @include border($border-color-header);
    grid-area: header;
}

footer {
    @include border($border-color-footer);
    grid-area: footer;
}

.space-below {
    margin-bottom: 1em;
}
.space-top {
    margin-top: 1em;
}
.space-around {
    margin-top: 1em;
    margin-bottom: 1em;
}
.space-bottom {
    margin-bottom: 2rem;
}
.space-left-right {
    margin-left: .5em;
    margin-right: .5em;
}

.border-thin {
    outline-color: darkgray;
    outline-style: solid;
    outline-width: thin;
}

// Allow mapbox controls on the left side to wrap. This is used by the
// buttons we add to toggle resort objects.
.mapboxgl-ctrl-top-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    bottom: 48px;
}

// Used by the maps2d component to make the SVG container resizable.
.svgimage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* Flow content across multiple columns using CSS3 columns.

http://cdn.tutsplus.com/webdesign/uploads/legacy/articles/040_css3_columns/examples/CSS3-Multiple-Column-Layout-Other.html

http://webdesign.tutsplus.com/articles/an-introduction-to-the-css3-multiple-column-layout-module--webdesign-4934
*/
@media (max-width: 520px) {
    .content-in-table {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}
@media (min-width: 521px) and (max-width: 1040px) {
    .content-in-table {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;

        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;

        -webkit-column-rule: 1px dotted #999999;
        -moz-column-rule: 1px dotted #999999;
        column-rule: 1px dotted #999999;
    }
}
@media (min-width: 1041px) and (max-width: 1560px) {
    .content-in-table {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        -webkit-column-rule: 1px dotted #999999;
        -moz-column-rule: 1px dotted #999999;
        column-rule: 1px dotted #999999;
    }
}
@media (min-width: 1561px) and (max-width: 2080px) {
    .content-in-table {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        -webkit-column-rule: 1px dotted #999999;
        -moz-column-rule: 1px dotted #999999;
        column-rule: 1px dotted #999999;

    }
}
@media (min-width: 2081px) {
    .content-in-table {
        -webkit-column-count: 5;
        -moz-column-count: 5;
        column-count: 5;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        -webkit-column-rule: 1px dotted #999999;
        -moz-column-rule: 1px dotted #999999;
        column-rule: 1px dotted #999999;

    }
}

.content-in-table ul {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;         /* IE 11 */
    display:table;                      /* Actually FF 20+ */
    width: 100%;
}

.ProseMirror-focused {
    outline: none;
}

@media (max-width: 768px) {
    .float-right-responsive {
        max-width: 100% !important;
        float: none;
    }
    .float-left-responsive {
        max-width: 100% !important;
        float: none;
    }
}
@media (min-width: 768px) {
    .float-right-responsive {
        float: right;
    }
    .float-left-responsive {
        float: left;
    }
}

.float-right-responsive {
    @extend .img-fluid;
    // @extend .float-right;
    @extend .d-block;
    height: auto;
    margin-left: 1em;
}
.float-left-responsive {
    @extend .img-fluid;
    // @extend .float-left;
    @extend .d-block;
    height: auto;
    margin-right: 1em;
}
.float-center-responsive {
    @extend .img-fluid;
    // @extend .float-none;
    @extend .d-block;
    @extend .mx-auto;
    text-align: center;
    height: auto;
}
.float-justify-responsive {
    @extend .img-fluid;
    // @extend .float-none;
    @extend .d-block;
    @extend .mx-auto;
    text-align: justified;
    height: auto;
}

.left {
    @extend .float-left-responsive;
}
.leftspace {
    @extend .float-left-responsive;
}

@font-face {
    font-family: "FontAwesome";
    src: url("~@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2");
}

.quote {
    @extend .blockquote;
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    background: #f9f9f9;
    padding: 1rem 1rem .5rem 2rem;
    // padding-right: 4rem;
    border-left: 5px solid #ccc;
    border-right: 5px solid #ccc;
    border-radius: 1.5rem;
    font-size: 1.2rem;
    position: relative;
    // text-align: center;

    // box-sizing: border-box;
    // border-radius: 1.5rem;
    // position: relative;
    // width: 100%;
    // padding: 20px 20px 20px 70px;
    // font: 'Merriweather Sans', sans-serif;
    // font-style: italic;
    // line-height: 1.5em;
    // background: #eee;
    // color: #898989;
}
.quote:before {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f10d";
    font-size: 1.2rem;
    font-style: normal;
    color: #cecece;
    position: absolute;
    top: .2rem;
    left: .5rem;
}
.quote:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f10e";
    font-size: 1.2rem;
    font-style: normal;
    color: #cecece;
    position: absolute;
    bottom: .2rem;
    right: .5rem;
}

@import 'swiper/scss';
@import 'swiper/css/autoplay';

.season-open {
    background-color: $green;
}

.season-closed {
    background-color: $red;
    color: white;
}

.figure-img-border {
    outline: 2px solid $blue;
}

.figure-img-noborder {
}

p {
    min-height: 1em;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.unicode {
    font-size: 1.4em;
}

$altitude-color: #0B7E9D;
$speed-color: #BB8D02;
    // #5E7F06;
    // #BD8400;
    //    #C25400;

.axis-altitudes line {
    stroke: $altitude-color;
}

.axis-altitudes path {
    stroke: $altitude-color;
}

.axis-altitudes text {
    // stroke: $altitude-color;
    stroke: #303030;
}
.altitudes {
    stroke: $altitude-color;
    stroke-width: 2.5;
    fill: none;
}
.altitude-bubble {
    stroke: $altitude-color;
    fill: $altitude-color;
}

.axis-speeds line {
    stroke: $speed-color;
}

.axis-speeds path {
    stroke: $speed-color;
}

.axis-speeds text {
    // stroke: $speed-color;
    stroke: #303030;
}
.speeds {
    stroke: $speed-color;
    stroke-width: 2.5;
    fill: none;
}
.speed-bubble {
    stroke: $speed-color;
    fill: $speed-color;
}


div.tooltip {
    position: absolute;
    text-align: left;
    width: 200px;
    font-size: 1em;
    line-height: 1.2em;
    height: 5em;
    padding: 2px;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}

div.contextmenu {
    position: absolute;

  // color: $modal-content-color;
  // pointer-events: auto;
  // background-color: $modal-content-bg;
  // background-clip: padding-box;
  // border: $modal-content-border-width solid $modal-content-border-color;
  // @include border-radius($modal-content-border-radius);
  // @include box-shadow($modal-content-box-shadow-xs);
  // // Remove focus outline from opened modal
  // outline: 0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

:-webkit-full-screen { height: 100vh; width: 100vw; }
